<template>
  <section :id="$options.name" class="rooms">
    <div v-for="(hotel, hotel_index) in hotels" :key="_uid+'_hotel_'+hotel_index">
      <h2 class="rooms__title" v-html="'Disponibilità'" />
      <b-row class="rooms__wrap">
        <b-col xl="9" class="rooms__content">
          <div class="rooms__element" v-for="(room, room_index) in hotel.room_data_parsed" :key="_uid+'_room_'+room_index">

            <!-- vb-card -->
            <vb-card
              v-if="room.block !== ''"
              :class="'rooms__item' + (is_rooms_selected(room) ? ' rooms__item--selected' : '')"
              variant="horizontal"
              :card="{
                image: room.room_photos ? room.room_photos[0].url_original : '/assets/images/common/statics/room_placeholder.png',
                title: room.room_name,
                text: room.room_description,
                subtitle: room.room_info.bedrooms && room.room_info.bedrooms.map(bed => `${bed.description.replace('/', ' oppure ')}`).join(', ')
              }"
              @click_title="$bvModal.show('modal_room_' + room_index)"
            >
              <div class="rooms__item-wrap">
                <rooms-info :room="room" />
                <div class="rooms__toggle" v-b-toggle="_uid+'_room_'+room_index">
                  <span class="rooms__toggle-price" v-if="price_starting_from(room) > 0" v-html="'da € ' + markup(price_starting_from(room)).live_price" />
                  <div class="rooms__toggle-btn">
                    <span class="rooms__toggle-text" v-html="'scegli'" />
                    <vb-icon class="rooms__toggle-icon" :name="'angle_small_down'" :color="sass('info')" :size="25" />
                  </div>
                </div>
              </div>
              <vb-icon v-b-modal="'modal_room_' + room_index" class="rooms__item-dotted" :name="'dotted'" :color="sass('dark')" :size="25" />
            </vb-card>
            <!-- // end vb-card-->

            <b-collapse
              class="rooms__collapse"
              :id="_uid+'_room_'+room_index"
              :visible="is_rooms_selected(room)"
            >
              <!-- block -->
              <div class="blocks">
                <b-row>
                  <b-col class="blocks__col blocks__col--first" xl="4">
                    <div class="blocks__summary">
                      <span class="blocks__summary-title" v-html="room.room_name" />
                      <div class="blocks__summary-bed" v-if="room.room_info.bedrooms">
                        <vb-icon class="blocks__summary-icon mr-1" :name="'doublebed'" :color="'000'" :size="15" />
                        <span v-html="room.room_info.bedrooms.map(bed => `${bed.description.replace('/', ' oppure ')}`).join(', ')" />
                      </div>
                      <span
                        class="blocks__summary-left"
                        :class="room.block[0].number_of_rooms_left === 1 && 'blocks__summary-left--alert'"
                        v-if="room.block[0] && room.block[0].number_of_rooms_left"
                        v-html="room.block[0].number_of_rooms_left + (room.block[0].number_of_rooms_left > 1 ? ' camere disponibili' : ' camera disponibile')"
                      />
                      <ul class="blocks__summary-facilities" v-if="room.room_facilities.length !== ''">
                        <li v-for="(facility, facility_index) in room.room_facilities" :key="_uid+'_facility_'+facility_index">
                          <span v-if="facility_index < 8" v-html="facility.name" />
                        </li>
                      </ul>
                      <span v-if="room.room_facilities.length > 8" v-b-modal="'modal_room_' + room_index" class="blocks__summary-show" v-html="'vedi tutti'" />
                    </div>
                  </b-col>
                  <b-col class="blocks__col blocks__col--second" xs="12" xl="8">
                    <b-row>
                      <b-col
                        md="12" class="blocks__element"
                        v-for="(block, block_index) in room.block"
                        :key="_uid+'_block_'+block_index"
                      >
                        <div
                          class="blocks__item"
                          :class="model_blocks[hotel_index][room_index][block_index].incremental_price.quantity > 0 ? 'blocks__item--selected' : ''"
                        >
                          <occupancy-block :block="block" :occupancy="occupancy" />
                          <div class="blocks__wrap">
                            <mealplan-refundable-block class="blocks__mealplan-refundable" :block="block" />
                            <div class="blocks__cta">
                              <p class="blocks__cta-title"
                                 v-html="get_block_cta(model_blocks[hotel_index][room_index][block_index].incremental_price.quantity)"
                              />
                              <div class="d-flex align-items-center">
                                <div class="blocks__cta-price">
                                  <span class="blocks__deal" v-if="block.deal_tagging && Object.values(block.deal_tagging).length">
                                    Ottimo prezzo!
                                  </span>
                                  <span class="blocks__cta-value" v-html="'€ ' +
                                    markup(charge_price_euro(block.min_price).charge_price).live_price"
                                  />
                                  <span class="blocks__cta-tax" v-html="'include gestione e assistenza'" />
                                </div>
                                <b-form-select
                                  class="blocks__cta-select"
                                  v-model="model_blocks[hotel_index][room_index][block_index].incremental_price"
                                  :options="get_options_maxrooms(room, block)"
                                  @change="solution_selected({
                                    hotel_index: hotel_index,
                                    room_index: room_index,
                                    block_index: block_index
                                  })"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <!-- // end block -->
            </b-collapse>

            <!-- modale room -->
            <vb-modal
              :vb_options="{
                id: 'modal_room_' + room_index,
                body_class: 'rooms__modal',
                title: room.room_name,
                size: 'lg',
                centered: true,
                hide_footer: true
              }"
            >
              <rooms-modal :room="room" />
            </vb-modal>
            <!-- // end modale room -->

          </div>
          <router-link
            class="rooms__info"
            :to="{ name: 'detail', hash: '#info' }"
          >
            <span v-html="hotel.hotel_data.name + ' potrebbe applicare costi aggiuntivi non mostrati qui sopra. Consulta la sezione <span class=\'text-info underline semibold\'>Da sapere</span> per avere più informazioni su questa struttura.'" />
          </router-link>
        </b-col>
        <b-col xl="3" class="rooms__resume" v-if=" hotel.room_data_parsed.length > 0">
          <div class="rooms__resume-wrap" :set_check_rooms="check_rooms = get_check_rooms()">
            <rooms-summary
              class="rooms__resume-summary"
              v-if="selected_solution.rooms && Object.keys(selected_solution.rooms).length > 0"
              :solution="selected_solution"
            />
            <vb-button
              :vb_options="{
                text: 'Continua',
                variant: 'info',
                icon_disabled: 'lock',
                block: true,
                disabled:
                  check_rooms.disabled ||
                  !get_availability(selected_solution.checkout_payload.live_price).can_go_to_checkout
              }"
              class="rooms__resume-btn"
              @click="scroll_to_bottom()"
            />
            <div v-if="check_rooms.disabled" :class="'rooms__resume-text text-' + check_rooms.variant" >
              <vb-icon class="rooms__resume-text_icon mr-1" :name="'info'" :color="sass(check_rooms.variant)" :size="14" />
              <p class="mb-0" v-html="check_rooms.label" />
            </div>

          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import Card from '@library/blocks/card'
import OccupancyBlock from '@booking/components/blocks/occupancy-block.vue'
import MealplanRefundableBlock from '@booking/components/blocks/mealplan-refundable-block.vue'
import RoomsInfo from '@booking/components/blocks/detail/rooms-info.vue'
import RoomsSummary from '@booking/components/blocks/rooms-summary.vue'
import RoomsModal from '@booking/components/blocks/detail/rooms-modal.vue'
import {number, smaller, subtract, add, string} from "mathjs"
import { markup, round_up } from '@booking/utilities.js'
import {deep_clone} from "@src/utilities"
import Vue from "vue"
import { mapState } from 'vuex'

export default {
  name: 'rooms',

  components: {
    'vb-card': Card,
    'occupancy-block': OccupancyBlock,
    'mealplan-refundable-block': MealplanRefundableBlock,
    'rooms-info': RoomsInfo,
    'rooms-summary': RoomsSummary,
    'rooms-modal': RoomsModal,
  },

  props: {
    hotels: Array,
    availabilities: Array,
    model_blocks: Array,
  },

  data() {
    return {
      config: Vue.prototype.$config,
      checkin: this.$store.state.livebk.checkin,
      checkout: this.$store.state.livebk.checkout,
      occupancy: deep_clone(this.$store.state.occupancy),
      hotel_id: this.$store.state.livebk.selected_hotel.hotel_id,
    }
  },

  computed: {
    ...mapState(
      'livebk', [
        'selected_solution',
        'room_selection'
      ]
    ),
    set_options_blocks() { // setta quali option abilitare/disabilitare nelle select dei blocchi
      return this.$store.state.livebk.room_selection.set_options_blocks || {}
    },
    rooms_selected() { // lista camere e blocchi selezionati, calcolo del prezzo totale e tasse di soggiorno
      return this.$store.state.livebk.room_selection.rooms_selected || {}
    }
  },

  created() {
    this.markup = markup
  },

  mounted() {
  },

  update() {
  },

  methods: {

    get_block_cta(model_price_qty) {
      const maxRooms = this.get_config('guiSettings.occupancy.maxRooms', 1)
      const { rooms } = this.selected_solution
      const label = maxRooms > 1 ? 'Quante camere?' : 'Scegli la camera'

      if (!rooms || Object.keys(this.selected_solution.rooms).length === 0) return label
      else if (model_price_qty === 0 && maxRooms === 1) return ''
      else return label
    },

    get_check_rooms(){

      const rooms = Object.keys(this.selected_solution).length &&
                    Object.values(this.selected_solution.checkout_payload.blocks).reduce((total, value) => total + value, 0),
            occupancy = this.occupancy.length,
            rooms_not_selected = rooms === 0,
            rooms_more_occupancy = rooms > occupancy,
            rooms_less_occupancy = rooms < occupancy

      let label = '',
          variant = ''

      if (rooms_not_selected) {
        label = 'Scegli una' + (this.config.guiSettings.maxRooms > 1 ? ' o più camere ' : ' camera ') +  '<span class=\'d-block mt-1 ml-n3 text--sm\'>Il numero di camere deve essere uguale al numero di camere che hai cercato nel motore di ricerca.</span>'
        variant = 'dark'
      } else if (rooms_more_occupancy) {
        label = 'Hai selezionato più camere del necessario <span class=\'d-block mt-1 ml-n3 text--sm\'>Il numero di camere ricercate e il numero di quelle selezionate devono essere uguali. Probabilmente ne hai selezionate troppe. Cambia la selezione o fai una nuova ricerca.</span>'
        variant = 'danger'
      } else if (rooms_less_occupancy) {
        label = 'Seleziona altre camere <span class=\'d-block mt-1 ml-n3 text--sm\'>Hai selezionato meno camere di quelle che hai cercato. Seleziona il numero giusto. Se hai cambiato idea, fai una nuova ricerca.</span>'
        variant = 'primary'
      }

      const result = {
        disabled: rooms !== occupancy,
        label,
        variant
      }

      this.$emit('check_rooms', result)

      return result
    },

    price_starting_from(room) {
      let min_tot_amount = 0

      room.block.forEach(block => {
        const tot_amount = block.min_price.currency !== 'EUR' ? block.min_price.other_currency.charge_price : block.min_price.charge_price
        if(min_tot_amount === 0 || smaller(tot_amount, min_tot_amount)) min_tot_amount = tot_amount
      })
      return min_tot_amount
    },

    is_rooms_selected(room) {
      return Boolean(this.rooms_selected.rooms &&
          Object.keys(this.rooms_selected.rooms).length &&
          this.rooms_selected.rooms.some(r => r.id_room === room.room_id))
    },

    scroll_to_bottom() {
      let target_el = document.getElementById('recap')

      if (target_el) {
        let offset_top = target_el.offsetTop
        window.scroll({top: offset_top, behavior: 'smooth'})
      }
    },

    counter_blocks_selected(data) {
      const result = {}

      for (const key in data) {
        const blocks = data[key].blocks
        for (const block_key in blocks) {
          result[block_key] = blocks[block_key].incremental_price_selected.quantity
        }
      }

      return result
    },

    select_options_block(room, model_room) {

      const obj_quantity = {}

      room.block.forEach((block, index) => {
        if (!obj_quantity[block.room_id]) {
          obj_quantity[block.room_id] = {}
        }
        obj_quantity[block.room_id][block.block_id] = model_room[index].incremental_price.quantity
      })

      room.block.forEach((block) => {
        if (!this.set_options_blocks[block.room_id]) {
          this.set_options_blocks[block.room_id] = {}
        }
        this.set_options_blocks[block.room_id][block.block_id] = room.block[0].number_of_rooms_left > this.occupancy.length ? this.occupancy.length : room.block[0].number_of_rooms_left
      })

      for (const key_room in this.set_options_blocks) {
        for (const key_diff in this.set_options_blocks[key_room]) {
          for (const key_room_quantity in obj_quantity) {
            for (const key_quantity in obj_quantity[key_room_quantity]) {
              if(key_room === key_room_quantity){
                if (key_diff !== key_quantity) {
                  this.set_options_blocks[key_room][key_diff] = subtract(this.set_options_blocks[key_room][key_diff], obj_quantity[key_room_quantity][key_quantity])
                }
              }
            }
          }
        }
      }
    },

    charge_price_euro(incr_price) {
      const is_euro = incr_price.currency === 'EUR'

      const convert_to_euro = (price) => {
        if (!is_euro) {
          return {
            ...price,
            amount: price.other_currency.amount,
            currency: price.other_currency.currency,
            extra_charges_breakdown: {
              extra_charge: price.extra_charges_breakdown.extra_charge.map(e => ({
                ...e,
                amount: e.other_currency.amount,
                currency: e.other_currency.currency
              })),
              net_price: price.extra_charges_breakdown.net_price_other_currency.price
            }
          }
        }
        return price
      }

      return {
        charge_price: is_euro ? incr_price.charge_price : incr_price.other_currency.charge_price,
        price: incr_price.price,
        extra_charges_breakdown: is_euro ? incr_price.extra_charges_breakdown : convert_to_euro(incr_price).extra_charges_breakdown
      }
    },

    get_options_maxrooms(room, block){
      const max_rooms = this.config.guiSettings.maxRooms,
            options = [{ value: { quantity: 0, charge_price: 0, price: 0, live_price: 0, extra_charges_breakdown: {} }, text: '0' }]

      const generate_option = (inc, inc_index, is_disabled) => {
        const charge_euro = this.charge_price_euro(inc),
              live_price = markup(charge_euro.charge_price).live_price

        return {
          value: {
            quantity: inc_index + 1,
            charge_price: string(charge_euro.charge_price),
            price: string(charge_euro.price),
            live_price: live_price,
            extra_charges_breakdown: charge_euro.extra_charges_breakdown
          },
          text: `${inc_index + 1} (€ ${live_price})`,
          disabled: is_disabled
        }
      }

      if (max_rooms === 1) {
        options.push(
          generate_option(
            block.incremental_price[0],
            0,
            this.selected_solution.rooms && Object.keys(this.selected_solution.rooms).length > 0
          )
        )
      } else {
        options.push(...block.incremental_price.slice(0, this.occupancy.length).map((inc, inc_index) =>
          generate_option(
            inc,
            inc_index,
            (this.set_options_blocks && this.set_options_blocks.hasOwnProperty(room.room_id)) && ((inc_index + 1) > this.set_options_blocks[room.room_id][block.block_id])
          ))
        )
      }

      return options
    },

    selected_rooms(block) {

      if (!this.rooms_selected.rooms) {
        this.rooms_selected.rooms = []
      }

      const exist_room_index = this.rooms_selected.rooms.findIndex(room => room.name === block.name)

      // se la quantità (option della select) è 0, rimuovo il blocco, se non ci sono più blocchi rimuovo anche la camera.
      if (block.incremental_price_selected.quantity === 0) {

        if (exist_room_index !== -1) {
          const room = this.rooms_selected.rooms[exist_room_index]
          delete room.blocks[block.block_id]

          if (Object.keys(room.blocks).length === 0) {
            this.rooms_selected.rooms.splice(exist_room_index, 1)
          }
        }
      }

      // aggiungo nuovi blocchi alle camere se la quantità è > di 0.
      else {

        if (exist_room_index === -1) {
          // creo la camere se non esiste con il relativo blocco.
          this.rooms_selected.rooms.push({
            id_room: block.room_id,
            name: block.name,
            blocks: { [block.block_id]: block }
          })
        } else {
          // se la camera esiste, aggiungo il blocco.
          this.rooms_selected.rooms[exist_room_index].blocks[block.block_id] = block
        }
      }


      //  elaboro le info sui prezzi delle camere e i costi aggiuntivi, salvando i
      //  risultati intermedi e finali negli attributi dell'oggetto this.rooms_selected.

      let charge_price = 0,
          net_price = 0,
          price = 0,
          live_price = 0,
          chargeable = [],
          not_chargeable = []

      for (const name in this.rooms_selected.rooms) {

        const room = this.rooms_selected.rooms[name]

        for (const block_id in room.blocks) {
          const block = room.blocks[block_id].incremental_price_selected,
                charge_price_block = number(block.charge_price),
                price_block = number(block.price),
                net_price_block = number(block.extra_charges_breakdown.net_price),
                live_price_block = number(block.live_price)

          charge_price = round_up(add(charge_price, charge_price_block))
          price = round_up(add(price, price_block))
          net_price = round_up(add(net_price, net_price_block))
          live_price = round_up(add(live_price, live_price_block))


          const extra_charges = block.extra_charges_breakdown.extra_charge

          for (const charge of extra_charges) {
            const charge_key = charge.name
            const charge_category = charge.chargeable ? chargeable : not_chargeable

            // tracciamento charge.excluded === true
            if (charge.excluded) {
              this.log('charge_excluded: ' + charge.excluded)
            }

            const existing_charge_index = charge_category.findIndex(item => item.name === charge_key)

            if (existing_charge_index !== -1) {
              charge_category[existing_charge_index].amount += charge.amount
            } else {
              charge_category.push({
                name: charge_key,
                amount: charge.amount,
                charge_price_mode: charge.charge_price_mode
              })
            }
          }
        }
      }


      this.rooms_selected.extra_charge = {chargeable, not_chargeable}
      this.rooms_selected.charge_price = string(charge_price)
      this.rooms_selected.price = string(price)
      this.rooms_selected.net_price = string(net_price)
      this.rooms_selected.live_price = string(live_price)
    },

    solution_selected(ids) {

      let model_hotel = this.model_blocks[ids.hotel_index],
          model_room = model_hotel[ids.room_index],
          model_block = model_room[ids.block_index]

      let hotel = this.hotels[ids.hotel_index],
          room = hotel.room_data_parsed[ids.room_index],
          block = room.block[ids.block_index]

      this.select_options_block(room, model_room)
      this.selected_rooms({...block, ...{incremental_price_selected: model_block.incremental_price}})

      const selected_solution = {

        hotel_name: hotel.hotel_data.name,
        hotel_address: hotel.hotel_data.address + ', ' + hotel.hotel_data.zip + ' ' + hotel.hotel_data.city,
        rooms : this.rooms_selected.rooms,

        checkout_payload: {
          accomodations:{
            multiroom_assignment: false,
          },
          checkin: this.checkin,
          checkout: this.checkout,
          occupancy: this.occupancy,
          hotel_id: this.hotel_id,
          charge_price: this.rooms_selected.charge_price,
          price: this.rooms_selected.price,
          live_price: this.rooms_selected.live_price,
          net_price: this.rooms_selected.net_price,
          extra_charge : this.rooms_selected.extra_charge,
          blocks: this.counter_blocks_selected(this.rooms_selected.rooms)
        }
      }

      const room_selection = {
        model_blocks: this.model_blocks,
        rooms_selected : this.rooms_selected,
        set_options_blocks: this.set_options_blocks,
      }

      this.$store.commit('livebk/selected_solution', selected_solution)
      this.$store.commit('livebk/room_selection', room_selection)
    },

  },
}
</script>

<style lang="scss" scoped>

  .rooms {
    @extend %section;
    @extend %section_margin;

    &__title {
      @extend %section__title;
    }

    &__element {
      margin-bottom: $grid-gutter-width;
      position: relative;

      @include media-breakpoint-up(lg){
        position: static;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item {

      &-wrap {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 10px;

        @include media-breakpoint-up(md){
          margin-top: 1.25rem;
          justify-content: space-between;
        }
      }

      &-dotted {
        position: absolute;
        top: 10px;
        right: 30px;
        cursor: pointer;
        display: none;

        @include media-breakpoint-up(lg){
          display: block;
        }
      }

      &--selected {
        ::v-deep {
          .vb-card__wrap {
            background-color: #fafcff;
            border-color: $gray-300;
          }
        }
      }
    }

    &__collapse {
      padding: 0 10px;

      @include media-breakpoint-up(xl){
        padding: 0;
      }
    }

    &__toggle {
      $this: &;
      display: flex;
      align-items: center;

      &-price {
        font-size: $font-size-md;
        color: $primary;
        background-color: $wild-sand;
        border-radius: $border-radius-lg;
        padding: 2px 3px;
        margin-right: $grid-gutter-width/3;
        display: none;

        @include media-breakpoint-up(lg){
          display: block;
        }
      }

      &-btn {
        display: flex;
        align-items: center;
      }

      &-text {
        color: $info;
        text-decoration: underline;
        font-weight: $vb-font-weight-semibold;
      }

      &-icon {
        position: relative;
        top: 3px;

        @at-root #{$this}.not-collapsed & {
          transform: rotate(-180deg);
        }
      }
    }

    &__info {
      border-radius: 10px;
      padding: 20px;
      background-color: #fafcff;
      font-size: $font-size-md;
      color: $dark;
      width: 100%;

      @include media-breakpoint-up(xl){
        margin-top: $grid-gutter-width;
      }
    }

    &__resume {
      margin-top: $grid-gutter-width*2;

      @include media-breakpoint-up(xl){
        margin-top: 0;
      }

      &-wrap {
        background-color: $white;
        border-radius: 10px;
        padding: 15px;
        overflow: hidden;

        @include media-breakpoint-up(xl){
          background-color: transparent;
          border-radius: 0;
          padding: 0;
          position: sticky;
          top: $grid-gutter-width/2;
          overflow: auto;
        }

        ::v-deep {
          .custom-link {
            a {
              display: block;
            }
          }
        }
      }

      &-btn {
        width: 100%;

        @include media-breakpoint-up(sm){
          width: 40%;
          float: right;
        }

        @include media-breakpoint-up(xl){
          width: 100%;
          float: none;
        }
      }

      &-text {
        margin-bottom: 0;
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        line-height: 18px;
        justify-content: center;

        @include media-breakpoint-up(sm){
          margin-top: 0;
        }

        @include media-breakpoint-up(xl){
          margin-top: $grid-gutter-width;
          margin-bottom: $grid-gutter-width/2;
        }

        &_icon {
          margin-top: 2px;
        }
      }

      &-summary {
        margin-bottom: $grid-gutter-width*2;
      }
    }
  }

  .blocks {
    margin-top: $grid-gutter-width/3;
    padding-bottom: $grid-gutter-width/3;

    @include media-breakpoint-up(xl){
      margin-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
    }

    &__col {

      &--first {
        display: none;

        @include media-breakpoint-up(xl){
          display: block;
        }
      }
    }

    &__element {
      margin-bottom: $grid-gutter-width/2;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item {
      background-color: $white;
      padding: $grid-gutter-width/2;
      border-radius: $grid-gutter-width/3;
      border: 1px solid transparent;

      @include media-breakpoint-up(xl){
        margin-left: -1rem;
      }

      &--selected {
        background-color: #fafcff;
        border-color: $gray-300;
      }
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      margin-top: $grid-gutter-width/3;
      flex-direction: column;

      @include media-breakpoint-up(md){
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &__deal {
      font-weight: normal;
      font-size: $font-size-md;
      color: $primary;
      margin-top: -20px;
    }

    &__mealplan-refundable {
      flex-basis: 65%;
    }

    &__cta {
      position: relative;
      margin-top: 15px;

      @include media-breakpoint-up(md){
        margin-top: 0;
      }

      &-title {
        font-size: $font-size-md;
        margin-bottom: 5px;
        text-align: right;

        @include media-breakpoint-up(md){
          position: absolute;
          top: -25px;
          right: 0;
          margin-bottom: $grid-gutter-width/3;
        }
      }

      &-price {
        display: flex;
        flex-direction: column;
        flex-basis: 60%;
        text-align: right;
        margin-right: $grid-gutter-width/2;

        @include media-breakpoint-up(sm){
          flex-basis: 100%;
        }
      }

      &-value {
        font-size: $font-size-xl;
        color: $primary;
        font-weight: $vb-font-weight-semibold;
      }

      &-tax {
        font-size: $font-size-sm;
        color: $gray-500;
        cursor: default;
        line-height: normal;
      }

      &-select {
        flex-basis: 50%;
      }
    }

    &__summary {
      position: sticky;
      top: $grid-gutter-width/2;
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width;

      &-title {
        margin-bottom: 5px;
        display: block;
        font-weight: $vb-font-weight-semibold;
      }

      &-icon {
        margin-top: 2px;
      }

      &-bed {
        color: $secondary;
        display: flex;
        align-items: flex-start;
        font-size: $font-size-md;
        font-weight: $vb-font-weight-semibold;
        line-height: normal;
      }

      &-left {
        display: flex;
        align-items: center;
        font-size: $font-size-md;
        margin-top: 10px;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: $vb-body-color;
          display: inline-block;
          border-radius: 100%;
          margin-right: 5px;
          margin-top: 2px;
        }

        &--alert {
          color: $danger;

          &:before {
            background-color: $danger;
          }
        }
      }

      &-facilities {
        padding: 0;
        margin: 10px 0 0;

        li {
          list-style: none;
          font-size: $font-size-sm;
          display: inline-block;
          vertical-align: middle;
          margin: 2px;
          color: $primary;
          background-color: rgba(255,255,255, .8);
          padding: 2px 5px;
          border-radius: 5px;

          &:empty {
            display: none;
          }
        }
      }

      &-show {
        color: $info;
        text-decoration: underline;
        font-size: $font-size-sm;
        cursor: pointer;
        display: inline-block;
        margin-top: 10px;
        margin-left: 5px;
      }
    }
  }

  ::v-deep {

    .vb-card {

      &__title {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }

      &__subtitle {
        color: $secondary;
        font-weight: $vb-font-weight-semibold;
      }

      &__text {
        height: 3.125rem;
        font-size: .875rem;
        overflow: hidden;
      }
    }
  }

</style>